<style lang="less" scoped>
.real-time-data {
  width: 100vw;
  height: 100vh;
  background-color: #000313;
  font-family: MiSans-Demibold, MiSans;
}
section {
  width: 100%;
  background:url('../../assets/imgs/real_time_data_bg.png') no-repeat center;
  background-size: cover;
  background-color: rgba(0,0,0,0.38);
  padding: 0 15px;
  overflow: hidden;
};
.title {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 0;
  .name-time {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .block {
      position: relative;
      top: -5px;
      font-size: 23px;
      font-weight: 600;
      color: #35CEFC;
    }
    .time {
      font-size: 12px;
      color: #8093D8;
    }
  }
};
.mid-content {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: #A5B9FF;
  padding: 15px 20px;
  border-radius: 8px 8px 0 0;
  background-color: #1c174b;
  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 190px;
    .left-top,
    .left-bottom {
      display: flex;
      justify-content: space-between;
      div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-basis: 90px;
        flex-shrink: 0;
        height: 40px;
        overflow: hidden;
      &:nth-child(2n - 1) {
        margin-right: 5px;
      }
      >span {
        display: inline-block;
        overflow: hidden;
      }
    }
    }
  }
  img {
    flex-shrink: 0;
    width: 100px;
    height: 100px;
  }
}
.icon-sign {
  width: 50px;
  margin-right: 15px;
}
.real-data-body {
  position: relative;
  padding-bottom: 20px;
}
.seeker-real-time-data {
  position: relative;
  height: 310px;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}
.seeker-title, .company-title{
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  height: 45px;
  padding-left: unit(19 / 350 * 100, vw);
  background-color: #090d27;
  span{
    font-size: unit(14 / 350 * 100, vw);
    font-family: MiSans-Medium, MiSans;
    color: #35CEFC;
  }
  .real_data_icon {
    width: unit(15 / 350 * 100, vw);
    // height: unit(15 / 758 * 100, vh);
    vertical-align: middle;
    margin-right: unit(7 / 758 * 100, vw);
  }
}
.company-title {
  margin-top: 0;
}
.data-carousel {
  width: 100%;
  height: 275px;
  overflow: hidden;
  transform: translateY(0);
  transition: all 2s ease-in-out;
  background: #0c1230;
}

ul {
  padding: 0 20px;
  li {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 13px;
    background-color: #0c1230;
    color: #A5B9FF;
    border-bottom: 1px solid #1e2546;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    span {
      margin-right: 6px;
    }
    .font-fixed {
      display: inline-block;
      max-width: unit(110 / 350 * 100, vw);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #6270A0;
    }
  }
}
.space {
  height: unit(15 / 758 * 100, vh);
  background-color: #0c1230;
}
.company-real-time-data {
  position: relative;
  height: 295px;
  border-radius: 0 0 8px 8px ;
  overflow: hidden;
}
.seekersName{
  display: inline-block;
  max-width: unit(100 / 350 * 100, vw);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.companyName {
  display: inline-block;
  max-width: unit(110 / 350 * 100, vw);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.seekerReaded {
  display: inline-block;
  max-width: unit(110 / 350 * 100, vw);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.companyReaded {
  display: inline-block;
  max-width: unit(100 / 350 * 100, vw);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.loading::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8)
}
.loading::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  width: 40px;
  height: 40px;
  background: url('./../../assets/svgs/loading.svg') center center no-repeat;
  background-size: 100% 100%;
  -webkit-animation: changePosition 1s linear infinite;
}
@-webkit-keyframes changePosition{
  0%{
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100%{
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
<template>
  <div class="real-time-data">
    <section>
      <div class="title" :class="loading.basicInfoName ? 'loading' :''">
        <img class="icon-sign" :src="require(`../../assets/svgs/icon_sign.svg`)" alt="" />
        <div class="name-time">
          <span class="block">{{basicInfoName}}</span>
          <span class="time">{{time}}</span>
        </div>
      </div>
      <div class="mid-content">
        <div class="left">
          <div class="left-top">
            <div>
              <span>总用户数</span>
              <span style="color: #FF6295">{{baseData.userTotal}}</span>
            </div>
            <div>
              <span>企业总数</span>
              <span style="color: #FCB30C">{{baseData.companyTotal}}</span>
            </div>
          </div>
          <div class="left-bottom">
            <div>
              <span>职位总数</span>
              <span style="color: #8FFCC3">{{baseData.jobTotal}}</span>
            </div>
            <div>
              <span>简历总数</span>
              <span style="color: #35CEFC">{{baseData.resumeTotal}}</span>
            </div>
          </div>
        </div>
        <img :src="imgUrl">
      </div>
      <div class="real-data-body">
        <div class="seeker-real-time-data">
          <div class="seeker-title">
            <img class="real_data_icon" :src="require(`../../assets/svgs/ic_user_real_data.svg`)" alt="" />
            <span>求职者实时数据</span>
          </div>
          <div class="data-carousel" ref="box">
            <ul class="seeker_ul" ref="seekers">
              <li v-for="(item,index) in seekersActualList" :key="index">
                <span ref="span" class="seekersName">{{ item.who }}</span>
                <span class="font-fixed">{{ item.do }}</span>
                <span class="seekerReaded">{{ item.for }}{{ item.patch }}</span>
              </li>
            </ul>
          </div>
          <div class="space"></div>
        </div>
        <div class="company-real-time-data">
          <div class="company-title">
            <img class="real_data_icon" :src="require(`../../assets/svgs/ic_company_real_data.svg`)" alt="" />
            <span>企业实时数据</span>
          </div>
          <div class="data-carousel" ref="box2">
            <ul class="company_ul" ref="company">
              <li v-for="(item,index) in companyActualList" :key="index">
                <span class="companyName">{{ item.who }}</span>
                <span class="font-fixed">{{ item.do }}</span>
                <span class="companyReaded">{{ item.for }}{{ item.patch }}</span>
              </li>
            </ul>
          </div>
            <div class="space"></div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import utils from './../../utils/utils';
import dayjs from 'dayjs'

export default {
  name: 'H5DataView',
  data() {
    return {
      loading: {
        basicInfoName: false
      },
      boxSeekerHeight: 0,
      boxCompanyHeight: 0,
      scrollSeekerHeight: 0,
      timerSeeker: null,
      scrollCompanyHeight: 0,
      seekersActualList: [],
      companyActualList: [],
      basicInfoName: null,
      timerActual: null,
      timerDate: null,
      headerTime: null,
      time: '',
      imgUrl: '',
      baseData: {}
    }
  },
  created() {
    this.getInfo();
    this.getWebConfig();
  },
  mounted() {
    this.headerTime = setInterval(() => {
      this.time = dayjs().format('YYYY-MM-DD HH:mm:ss')
    }, 1000)
    if (this.timerDate) {
      window.clearInterval(this.timerDate);
    };
    if (this.timerActual) {
      window.clearInterval(this.timerActual);
    };
    const token = this.$route.query.token || '';
    sessionStorage.setItem('talents_data_view_token', decodeURIComponent(token));
    this.getActualseeker();
    this.getActualCompany();
    this.actualInterval();
    this.timeInterval();
  },
  beforeDestroy() {
    window.clearInterval(this.timerActual);
    window.clearInterval(this.headerTime);
  },
  methods: {
    async getWebConfig() {
      const res = await this.$api.platform.getWebConfig()
      this.imgUrl = res.wechat_qrcode
    },
    async getInfo() {
      this.loading.basicInfoName = true;
      try {
        const res = await this.$api.platform.getBasicInfo();
        console.log(res)
        this.$set(this, 'baseData', res)
        this.basicInfoName = res.dashboard.name;
        document.title = this.basicInfoName;
      } catch (err) {
        console.log(err)
      } finally {
        this.loading.basicInfoName = false;
      }
    },
    IsPC() {
      if ((!navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
        this.$router.push({ path: 'index', query: { token: sessionStorage.getItem('talents_data_view_token') } });
      };
    },
    actualInterval() {
      if (this.timerActual) {
        window.clearInterval(this.timerActual);
      }
      this.timerActual = setInterval(() => {
        this.getActualseeker();
        this.getActualCompany();
      }, 60 * 1000);
    },
    timeInterval() {
      if (this.timerDate) {
        window.clearInterval(this.timerDate);
      }
      this.timerDate = setInterval(() => {
        this.dateTime = utils.handleDateObjectTime(new Date(), '.')
      }, 1000);
    },
    // 获取实时求职者数据
    async getActualseeker() {
      try {
        const res = await this.$api.platform.getActualseeker({ limit: 20 });
        this.seekersActualList = res.list || [];
        this.$nextTick(() => {
          this.boxSeekerHeight = this.$refs.seekers.offsetHeight - this.$refs.box.offsetHeight;
          this.setSeekerScrollList();
        })
      } catch (error) {
        console.log(error);
      }
    },
    // 求职者定时器
    setSeekerScrollList() {
      if (this.timerSeeker) {
        window.clearInterval(this.timerSeeker)
      };
      const li = document.querySelector('li');

      this.scrollSeekerHeight = 0;

      this.$refs.seekers.style.transform = `translateY(-${this.scrollSeekerHeight}px)`;

      this.timerSeeker = setInterval(() => {
        if (this.scrollSeekerHeight + li.offsetHeight < this.boxSeekerHeight) {
          this.scrollSeekerHeight = this.scrollSeekerHeight + li.offsetHeight + 0.25;
        } else {
          this.scrollSeekerHeight = 0;
        };
        this.$refs.seekers.style.transform = `translateY(-${this.scrollSeekerHeight}px)`;
      }, 3000);
    },
    // 实时企业
    async getActualCompany() {
      try {
        const res = await this.$api.platform.getActualCompany({ limit: 20 });
        this.companyActualList = res.list || [];
        this.$nextTick(() => {
          this.boxCompanyHeight = this.$refs.company.offsetHeight - this.$refs.box.offsetHeight;
          this.setCompanyScrollList();
        });
      } catch (err) {
        console.log(err);
      }
    },
    // 企业定时器
    setCompanyScrollList() {
      if (this.timerCompany) {
        window.clearInterval(this.timerCompany)
      };
      const li = document.querySelector('li');
      this.scrollCompanyHeight = 0;
      this.$refs.company.style.transform = `translateY(-${this.scrollCompanyHeight}px)`;
      this.timerCompany = setInterval(() => {
        if (this.scrollCompanyHeight + li.offsetHeight < this.boxCompanyHeight) {
          this.scrollCompanyHeight = this.scrollCompanyHeight + li.offsetHeight + 0.25;
        } else {
          this.scrollCompanyHeight = 0;
        };
        this.$refs.company.style = `transform: translateY(-${this.scrollCompanyHeight}px)`;
      }, 3000);
    }
  }
}
</script>
